import React from 'react';
import './AboutUsSection.css';
import { Link } from 'react-router-dom';
import AboutSectionImg from '../../utils/images/about-section-img.jpg';

function AboutUsSection() {
  return (
 <div className="container my-5" dir="rtl">
      <div className="row flex-lg-row-reverse">
        <div className="col-lg-6 d-flex justify-content-center">
          <img src={AboutSectionImg} className='about-section-img img-fluid mb-5 mb-lg-0 shadow' alt="אודותינו" />
        </div>
        <div className="col-lg-6 d-flex flex-column justify-content-center align-items-end text-end">
          <div className="w-100">
            <h2 className='text-uppercase fw-bold fs-1 mb-4 mb-lg-5 text-end'>אודותינו</h2>
            <p>הבריאפוד נוסד ב-2022 מתוך אהבה לאוכל טרי ואיכותי. אנו מאמינים שהטעם הטוב ביותר מגיע ישירות מהאדמה לצלחת. כל בוקר, השפים שלנו מבקרים בשווקים המקומיים לבחור את המרכיבים הטריים ביותר ליצירת מנות מרהיבות ועשירות בטעמים.</p>
            <p className='mb-4 mb-lg-5'>אנו גאים להציע חוויה קולינרית ייחודית המשלבת מסורת עם חדשנות, ומביאה את טעמי השוק הישר לשולחנכם. הצטרפו אלינו למסע טעמים עשיר ומרגש, המשתנה בכל עונה ומציע את מיטב התוצרת המקומית.</p>
            <Link to='/about'>
              <button type='button' className='btn btn-dark btn-lg text-capitalize shadow'>עוד עלינו</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsSection;