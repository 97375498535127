import React from 'react';
import './MenuSection.css';
import food from '../../utils/food';
import drinks from '../../utils/drinks';
import MenuBtn from '../MenuBtn/MenuBtn';


function MenuSection() {
  return (
    <div className="menu-section py-5 text-light shadow" dir="rtl">
      <div className="container">
        <h2 className='fs-1 mb-5 fw-bold text-end'>המומלצים שלנו</h2>
        <div className="row mb-4 mb-lg-5">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <h3 className='fs-2 mb-4 fw-bold text-end'>ארוחות</h3>
            <ul className='list-unstyled'>
              {food.map((item) => (
                <li key={item.id} className='d-flex justify-content-between align-items-center mb-2'>
                  <p className='fs-5 mb-0 text-success fw-bold'>{item.price}</p>
                  <p className='fs-5 mb-0 text-end'>{item.name}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-lg-6 mb-4 mb-lg-0">
            <h3 className='fs-2 mb-4 fw-bold text-end'>משקאות</h3>
            <ul className='list-unstyled'>
              {drinks.map((item) => (
                <li key={item.id} className='d-flex justify-content-between align-items-center mb-2'>
                  <p className='fs-5 mb-0 text-success fw-bold'>{item.price}</p>
                  <p className='fs-5 mb-0 text-end'>{item.name}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <MenuBtn />
        </div>
      </div>
    </div>
  );
}


export default MenuSection;