import React from 'react';

function ValidFeedback() {
  return (
    <div className="valid-feedback">
       
    </div>
  )
}

export default ValidFeedback;