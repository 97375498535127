import React, {useEffect} from 'react';
import './About.css';
import AboutChef1Img from '../../utils/images/about-chef1-img.jpg';
import AboutChef2Img from '../../utils/images/about-chef2-img.jpg';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import Reviews from '../../components/Reviews/Reviews';


function About() {

useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='about-page' dir="rtl">
       <section id="about-us">
        <header className='height-50 mt-5'>
          <div className="container h-100 d-flex align-items-center justify-content-center">
            <h1 className='text-light'>עלינו על קצה המזלג</h1>
          </div>
        </header>

      <div className="container my-5">
        <p>אודותינו
הבריאפוד נוסד ב-2022 מתוך אהבה לאוכל טרי ואיכותי. אנו מאמינים שהטעם הטוב ביותר מגיע ישירות מהאדמה לצלחת. כל בוקר, השפים שלנו מבקרים בשווקים המקומיים לבחור את המרכיבים הטריים ביותר ליצירת מנות מרהיבות ועשירות בטעמים. אנו גאים להציע חוויה קולינרית ייחודית המשלבת מסורת עם חדשנות, ומביאה את טעמי השוק הישר לשולחנכם.</p>
        <p>ב"מסעדת הבריאפוד" אנו מתייחסים למאכלים שלנו כאל אמנות, ולסועדים שלנו כאל אורחים חשובים. השירות האדיב והמקצועי שלנו יבטיח לכם חווית ארוחה נעימה ומספקת. אנו שמים דגש על אווירה נינוחה ומזמינה, כדי שתוכלו להתרווח ולהתענג על האוכל המיוחד שלנו בסביבה נוחה ונעימה.

המסעדה שלנו ממוקמת בלב העיר, במקום נגיש וקל למצוא. אם אתם מחפשים מקום לאירועים מיוחדים, פגישות עסקיות או פשוט ארוחה טובה עם חברים ומשפחה, "הבריאפוד" הוא הבחירה המושלמת.</p>

        <div className="row">
          <div className="col-lg-6">
            <img src={AboutChef1Img} className='img-fluid my-4' alt="" />
          </div>
          <div className="col-lg-6">
            <img src={AboutChef2Img} className='img-fluid my-4' alt="" />
          </div>
        </div>

הצטרפו אלינו לחוויה קולינרית שלא תשכחו – בואו לגלות את הטעמים הטריים והחדשים של "הבריאפוד". אנו מחכים לראותכם ולהעניק לכם את השירות והאוכל הטובים ביותר!      </div>

      <div className="bg-dark text-light">
        <ImageGallery />
      </div>

      <div className="my-5">
        <Reviews />
      </div>
      </section>
    </div>
  )
}

export default About;