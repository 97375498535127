const drinks = [
    {
        id: 1,
        name: '₪12',
        price: 'מיץ אורגני טרי'
    },
    {
        id: 2,
        name: '₪10',
        price: 'קפה'
    },
    {
        id: 3,
        name: '₪22',
        price: 'אלכוהול'
    }
];

export default drinks;