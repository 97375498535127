import React from 'react';
import './Menu.css';
import { Card, CardBody, CardText, CardTitle } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BreakfastImg from '../../utils/images/breakfast-img.jpg';
import LunchImg from '../../utils/images/lunch-img.jpg';
import DinnerImg from '../../utils/images/dinner-img.jpg';
import DessertImg from '../../utils/images/dessert-img.jpg';
import DrinksImg from '../../utils/images/drinks-img.jpg';

const breakfast = [
  {
      id: 1,
      name: 'ארוחת בוקר אנגלית',
      description: 'בייקרון מעושן, נקניקיה, עגבניה,פטריות, שעועית אפויה, ביצים',
      price: '₪68'
  },
  {
      id: 2,
      name: 'טוסט אבוקדו',
      description: 'ביצה עלומה, אבוקדו, במל, עגבניות, לח מחמצת',
      price: '₪34'
  },
  {
      id: 3,
      name: 'בוריטו',
      description: 'טורטיה מתירס, ביצים, גבינת מוצרלה, תפוחי אדמה, בשר בקר ',
      price: '₪31'
  }
];
const lunch = [
  {
      id: 1,
      name: 'סלט קיסר',
      description: 'חזה עוף במרינדה, חסה טריה, קרוטוני הבית, פרמזן',
      price: '₪28'
  },
  {
      id: 2,
      name: 'ספגטי קרבונרה',
      description: 'ספגטי בעבודת יד, אווז, שום, ביצים, פרמזן, פלפל אדום',
      price: '₪42'
  },
  {
      id: 3,
      name: 'פיצת הבית',
      description: 'נקניק בקר חריף, סלמי איטלקי,עגבניות, פטריות, זיתים',
      price: '₪40'
  }
];

const dinner = [
  {
      id: 1,
      name: 'בקר מעושן ופיקנטי',
      description: 'בקר מבושל בבישול ארוך, תפוח , גזרים, רוטב גבינות הבית, בלנד מיוחד של תבלינים',
      price: '₪64'
  },
  {
      id: 2,
      name: 'ספגטי בולונז',
      description: 'בצל, גזר, סלרי, בשר בקר טחון, ספגטי בעבודת יד, פרמזן',
      price: '₪45'
  },
  {
      id: 3,
      name: 'קארי עוף',
      description: 'בצל, שום, פטריות, עגבניות, בלנד תבלינים מיוחד',
      price: '₪52'
  }
];

const dessert = [
  {
      id: 1,
      name: 'טארט לימון',
      description: 'גרידת לימון ,קינמון , ריבת לימון',
      price: '₪26'
  },
  {
      id: 2,
      name: 'סינמון רול',
      description: 'מאפה קינמון חם בתוספת קרם שמנת משובח',
      price: '₪29'
  },
  {
      id: 3,
      name: 'פנקייק טבעוני',
      description: 'פנקייק בננה על בסיס חלב שקדים בתוספת אוכמניות ',
      price: '₪32'
  }
];

const drinks = [
  {
      id: 1,
      name: 'מיץ טבעי',
      price: '₪14'
  },
  {
      id: 2,
      name: 'קפה',
      price: '₪12'
  },
  {
      id: 3,
      name: 'אלכוהול',
      price: '₪25'
  },
  {
    id: 4,
    name: 'תה',
    price: '₪12'
  },
  {
    id: 5,
    name: 'מים',
    price: '₪8'
  }
];

function Menu() {
  return (
    <div className='menu-page'>
      <header className='height-50 mt-5'>
        <div className='container h-100 d-flex align-items-center justify-content-center'>
          <h1 className='text-light'>תפריט</h1>
        </div>
      </header>

      <div className='breakfast my-5'>
        <div className='container'>
          <h2 className='text-center fs-1 mb-4 mb-lg-5 text-uppercase fw-bold text-success'>ארוחת בוקר</h2>
          <div className='row flex-column-reverse flex-lg-row'>
            <div className='col-lg-6 d-flex justify-content-center'>
              <img src={BreakfastImg} className='img-fluid w-75 mt-4 mt-lg-0' alt="" />
            </div>
            <div className='col-lg-6 d-flex flex-column justify-content-around'>
              {breakfast.map((breakfast) => (
                <div key={breakfast.id}>
                  <Card className='border-0'>
                    <CardBody>
                      <CardTitle className='text-center fs-3 text-capitalize'>
                        {breakfast.name}
                      </CardTitle>
                      <CardText className='text-center fs-5'>
                        {breakfast.description}
                      </CardText>
                      <CardText className='text-center fs-3 fw-bold text-success'>
                        {breakfast.price}
                      </CardText>
                    </CardBody>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='lunch bg-dark text-light py-5'>
        <div className='container'>
          <h2 className='text-center fs-1 mb-4 mb-lg-5 text-uppercase fw-bold text-success'>ארוחת צהריים</h2>
          <div className='row'>
            <div className='col-lg-6 d-flex flex-column justify-content-around'>
              {lunch.map((lunch) => (
                <div key={lunch.id}>
                  <Card className='border-0 bg-dark text-light'>
                    <CardBody>
                      <CardTitle className='text-center fs-3 text-capitalize'>
                        {lunch.name}
                      </CardTitle>
                      <CardText className='text-center fs-5'>
                        {lunch.description}
                      </CardText>
                      <CardText className='text-center fs-3 fw-bold text-success'>
                        {lunch.price}
                      </CardText>
                    </CardBody>
                  </Card>
                </div>
              ))}
            </div>
            <div className='col-lg-6 d-flex justify-content-center'>
              <img src={LunchImg} className='img-fluid w-75 mt-4 mt-lg-0' alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className='dinner my-5'>
        <div className='container'>
          <h2 className='text-center fs-1 mb-4 mb-lg-5 text-uppercase fw-bold text-success'>ארוחת ערב</h2>
          <div className='row flex-column-reverse flex-lg-row'>
            <div className='col-lg-6 d-flex justify-content-center'>
              <img src={DinnerImg} className='img-fluid w-75 mt-4 mt-lg-0' alt="" />
            </div>
            <div className='col-lg-6 d-flex flex-column justify-content-around'>
              {dinner.map((dinner) => (
                <div key={dinner.id}>
                  <Card className='border-0'>
                    <CardBody>
                      <CardTitle className='text-center fs-3 text-capitalize'>
                        {dinner.name}
                      </CardTitle>
                      <CardText className='text-center fs-5'>
                        {dinner.description}
                      </CardText>
                      <CardText className='text-center fs-3 fw-bold text-success'>
                        {dinner.price}
                      </CardText>
                    </CardBody>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='dessert bg-dark text-light py-5'>
        <div className='container'>
          <h2 className='text-center fs-1 mb-4 mb-lg-5 text-uppercase fw-bold text-success'>קינוחים</h2>
          <div className='row'>
            <div className='col-lg-6 d-flex flex-column justify-content-around'>
              {dessert.map((dessert) => (
                <div key={dessert.id}>
                  <Card className='border-0 bg-dark text-light'>
                    <CardBody>
                      <CardTitle className='text-center fs-3 text-capitalize'>
                        {dessert.name}
                      </CardTitle>
                      <CardText className='text-center fs-5'>
                        {dessert.description}
                      </CardText>
                      <CardText className='text-center fs-3 fw-bold text-success'>
                        {dessert.price}
                      </CardText>
                    </CardBody>
                  </Card>
                </div>
              ))}
            </div>
            <div className='col-lg-6 d-flex justify-content-center'>
              <img src={DessertImg} className='img-fluid w-75 mt-4 mt-lg-0' alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className='drinks my-5'>
        <div className='container'>
          <h2 className='text-center fs-1 mb-4 mb-lg-5 text-uppercase fw-bold text-success'>שתייה</h2>
          <div className='row flex-column-reverse flex-lg-row'>
            <div className='col-lg-6 d-flex justify-content-center'>
              <img src={DrinksImg} className='img-fluid w-75 mt-4 mt-lg-0' alt="" />
            </div>
            <div className='col-lg-6 d-flex flex-column justify-content-around'>
              {drinks.map((drink) => (
                <div key={drink.id}>
                  <Card className='border-0'>
                    <CardBody>
                      <CardTitle className='text-center fs-3 text-capitalize'>
                        {drink.name}
                      </CardTitle>
                      <CardText className='text-center fs-3 fw-bold text-success'>
                        {drink.price}
                      </CardText>
                    </CardBody>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="container my-5 d-flex justify-content-center">
        <Link to='/contact'>
          <button type='button' className='btn btn-success btn-lg text-capitalize mx-2 shadow'>הזמן שולחן</button>
        </Link>
      </div>
    </div>
  )
}

export default Menu;