import React from 'react';
import { ChevronDown } from 'lucide-react';

const ScrollDownArrow = () => {
  const scrollToNextSection = () => {
    const header = document.querySelector('header');
    if (header) {
      const nextSection = header.nextElementSibling;
      if (nextSection) {
        nextSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <div className="scroll-down-arrow" onClick={scrollToNextSection}>
      <ChevronDown size={80} color="#d6d0c1" />
    </div>
  );
};

export default ScrollDownArrow;