import React from 'react';
import './Header.css';
import MenuBtn from '../MenuBtn/MenuBtn';
import { Link } from 'react-router-dom';
import ScrollDownArrow from './ScrollDownArrow';

function Header() {
  return (
    <header className='header-container d-flex align-items-center text-light shadow' dir="rtl">
      <div className="container-fluid">
        <div className='row'>
          <div className='col-12 offset-wrapper'>
            <div className='col-md-8 col-lg-6 text-container text-end'>
              <h2 className='welcome-text mb-3'>ברוכים הבאים למסעדת הבריאות שלנו</h2>
              <h1 className='restaurant-name mb-4'>הבריאפוד</h1>
              <p className='description-text mb-5'>
                ברוכים הבאים הבריאפוד, המקום שבו טריות פוגשת טעם! אנו מביאים את חוויית השוק הססגונית ישירות לצלחת שלכם. מנות עונתיות המוכנות מדי יום מהמרכיבים הטריים ביותר שהשוק מציע.
              </p>
              <div className='button-container d-flex justify-content-end'>
                <Link to='/contact'>
                  <button type='button' className='btn btn-outline-light btn-lg text-capitalize ms-2 shadow'>צרו קשר</button>
                </Link>
                <MenuBtn />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollDownArrow />
    </header>
  );
}

export default Header;