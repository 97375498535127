import React, { useEffect, useState } from 'react';

function Footer() {
    const [newDate, setNewDate] = useState('');
    useEffect(() => {
        let todayDate = new Date();
        let todayYear = todayDate.getFullYear();
        setNewDate([todayYear])
    }, []);

  return (
    <footer className='bg-body-tertiary'>
        <p className='p-3 m-0 text-center'>
          <a href="https://codesuits.com" target="_blank" rel="noopener noreferrer"> CodeSuits</a>  האתר עוצב ונבנה על ידי חברת 
        </p>
        
    </footer>
  )
}

export default Footer;