import React from 'react';
import './Reviews.css';
import { Card, CardBody, CardText, CardFooter, CardTitle } from 'react-bootstrap';
import Person1Img from '../../utils/images/person1-img.jpg';
import Person2Img from '../../utils/images/person2-img.jpg';
import Person3Img from '../../utils/images/person3-img.jpg';
import Person4Img from '../../utils/images/person4-img.jpg';

const reviews = [
    {
        id: 1,
        description: 'המסעדה שוק טרי היא המקום המושלם לארוחה איכותית ומגוונת. התפריט מציע מגוון רחב של מנות, כולן עשויות עם חומרי גלם טריים ואיכותיים. השירות היה מקצועי ומסביר פנים, והאווירה במתחם נוחה ומזמינה. בהחלט אחזור לשם בקרוב!"',
        img: [Person1Img],
        name: 'יובל ב'
    },
    {
        id: 2,
        description: 'הבריאפוד היא הבחירה המושלמת אם אתם מחפשים אוכל טרי וטעים. התפריט מציע שפע של מנות בריאות וטובות, השירות היה נעים ומקצועי. האווירה בהבריאפוד רגועה, והמסעדה נקייה מאוד. אני בהחלט אשוב לשם בקרוב',
        img: [Person2Img],
        name: 'שירן ז'
    },
    {
        id: 3,
        description: 'הייתי בבריאפוד עם חברים והיה לנו ערב נהדר. השירות היה מהיר ויעיל, והמנות יצאו במהירות ובמראה מרהיב. האוכל היה טרי וטעים, במיוחד המנות הים תיכוניות. המיקום של המסעדה נוח, והאווירה נינוחה ונעימה. מומלץ בחום',
        img: [Person3Img],
        name: 'אנה ש'
    },
    {
        id: 4,
        description: 'חוויית האוכל בבריאפודי הייתה מדהימה! האוכל היה טרי וטעים, במיוחד הסלטים המיוחדים והמיצים הטריים. נהניתי מאוד מהמנות הראשונות והעיקריות, וכל זאת במחיר הוגן. רק חבל שהמקום היה די רועש, מה שפגם מעט בחוויית הארוחה',
        img: [Person4Img],
        name: 'ניק ש'
    }
];

function Reviews() {
  return (
    <div className='reviews-section container' dir="rtl">
        <h2 className='text-center mb-5 text-uppercase fw-bold fs-1'>מה חושבים עלינו</h2>
        <div className="row g-4">
            {reviews.map((review) => (
                <div key={review.id} className='col-lg-6'>
                    <Card className="h-100 shadow">
                        <CardBody>
                            <div className="p-4">
                                <CardText>{review.description}</CardText>
                            </div>
                        </CardBody>
                        <CardFooter className="d-flex align-items-center">
                            <img src={review.img} className="img-fluid rounded-circle mx-3 shadow" alt="" />
                            <CardTitle className="text-success">{review.name}</CardTitle>
                        </CardFooter>
                    </Card>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Reviews;