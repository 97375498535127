import React from 'react';

function ContactInfo() {
  return (
    <div className='d-flex flex-column align-items-center'>
        <h2 className='fs-1 mb-3 text-uppercase fw-bold'>איפה למצוא אותנו</h2>
        <p className='mb-5'>רחוב רוטשילד 67</p>
        <h3 className='text-capitalize'>שעות פתיחה</h3>
        <p className="m-0">ראשון - שישי : 09:00 - 17:00</p>
        <p>שבת: 10:00 - 15:00</p>
    </div>
  )
}

export default ContactInfo;