const food = [
    {
        id: 1,
        name: '₪58',
        description: 'ביצים לבחירה, סלט ירקות טרי, גבינות מקומיות, לחם מחמצת',
        price: 'ארוחת בוקר'
    },
    {
        
        id: 2,
        name: '₪52',
        description: 'פסטה ביתית, ירקות צלויים מהשוק, שמן זית מקומי, עשבי תיבול',
        price: 'פסטה טרייה עם ירקות עונתיים'
    },
    {
        id: 3,
        name: '₪78',
        description: 'בשר בקר טרי, ירקות שורש עונתיים, תבלינים טריים מהשוק',
        price: 'נתחי בקר בתיבול השוק'
    }
];

export default food;